import React from 'react';
import axios from 'axios';
import $ from "jquery";
import Num2persian from 'num2persian';

const stok_city_arr = [["آبادان","abadan"],["اراک","arak"],["اردبیل","ardabil"],["ارومیه","urmia"],["اصفهان","isfahan"],["ایلام","ilam"],["اهواز","ahvaz"] ,["بجنورد","bojnurd"],["بندرعباس","bandar-abbas"],["بوشهر","bushehr"],["بیرجند","birjand"],["تبریز","tabriz"],["تهران","tehran"],["خرم آباد","khorramabad"],["رشت","rasht"],["زاهدان","zahedan"],["زنجان","zanjan"],["ساری","sari"],["سنندج","sanandaj"],["شهرکرد","shahrekord"],["شیراز","shiraz"],["قزوین","qazvin"],["قشم","qeshm"],["قم","qom"],["کرج","karaj"],["کرمان","kerman"],["کرمانشاه","kermanshah"],["کیش","kish"],["گرگان","gorgan"],["مشهد","mashhad"],["یاسوج","yasuj"],["یزد","yazd"]];


function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}



let is_admin = false;
if(localStorage.getItem('user_Role') == 888){
      is_admin = true;
    }
  let city_split;
  let cars_info_arr = new Array();
  let market_info_arr = new Array();




  var
  persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  fixNumbers = function (str)
  {
    if(typeof str === 'string')
    {
      for(var i=0; i<10; i++)
      {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  };

export default class Dashboard extends React.Component {
    state  = {
        market_list: [],
        api_list: [],
        cars_list: []

    }
    





    componentDidMount() {


              
    if( is_admin === true){

      axios.post('https://rentjoo.com/api/panel/user/market/list' ,{
        user_Id: localStorage.getItem('user_Username'),
        _token:localStorage.getItem('_token')
    })
        .then(res => {
          const market_list = res.data;
          this.setState({ market_list });
        })

        
        axios.post('https://rentjoo.com/api/panel/cars/stocks' ,{
          user_Id: localStorage.getItem('user_Username'),
          _token:localStorage.getItem('_token')
      })
    .then(res => {
    //  var myObject = JSON.parse(res.data) ;
      const cars_list = res.data;
      this.setState({ cars_list });

   
    })

      }
  

  
      $(".db_main").addClass("loading");
      $(".loader_box").show();
      axios.post('https://rentjoo.com/api/panel/orders/list' ,{
        user_Id: localStorage.getItem('user_Username'),
        _token:localStorage.getItem('_token')
    })
        .then(res => {
          const api_list = res.data;

          if(api_list!== null){
          this.setState({ api_list });
          }
          $(".db_main").removeClass("loading");
          $(".loader_box").hide();
        })
  
    }
  

    add_open_modal = (e) => {
      $("#order_modal").addClass('in');
      $('#save-form-btn').removeClass('hidden');
      $('#submit_date').addClass('hidden');
      $("#order_modal input ,#order_modal textarea,#order_modal select ").val("");
      $("#order_modal .modal-title").text("افزودن سفارش جدید");
      
  
  }
    submit_form = (e) => {
    


      var form_color = $('#color_val').val();
      var form_model = $('#model_val').val();
      var form_order = $('#order_val').val();
      form_order = form_order.split("/");
      var form_cars_info  = form_order[0] + "/"+ form_order[1] + "/"+form_model + "/" +form_color ;
      var form_order_id = $('#order_id_val').val();
      var form_market= $('#market_val').val();
      var form_name = $('#name_val').val();
      var form_number = $('#number_val').val();
      var form_city = $('#city_val').val();
      var form_price = $('#price_val').val();
      var form_date_o = $('#date_o_val').val();
      var form_count = $('#count_val').val();
      var form_discription = $('#discription_val').val();

  
  //console.log(form_name,form_username,form_mobile,form_phone,form_city,form_address,form_pass,form_stus,form_avatar);
    
  
    $.ajax({

      type: "POST",
      url: "https://rentjoo.com/api/panel/orders/add",
      data: {
       user_Id: localStorage.getItem('user_Username'),
       _token:localStorage.getItem('_token'),
       form_order:form_order[0],
       form_cars_info:form_cars_info,
       form_market:form_market,
       form_name:form_name,
       form_number:form_number,
       form_city:form_city,
       form_price:form_price,
       form_date_o:form_date_o,
       form_count:form_count,
       form_discription:form_discription
     },
    beforeSend: function () {
      

      $(".save_form_btn span").text("درحال ثبت");
      $(".save_form_btn .ico-2").addClass("en");
      $(".save_form_btn").attr('disabled', 'disabled');
  
  
    },
    success: function (result) {
     
      window.location.reload(); 
  
  
    }
  });
    
    }

    alarm_stus = (e) => {
      $(e.currentTarget).toggleClass("on");
      if($(e.currentTarget).hasClass("on")){
        $(e.currentTarget).parent().find(".ajax_btn").attr('data-alarm', "true");
      }
      else{
        $(e.currentTarget).parent().find(".ajax_btn").attr('data-alarm', "false");
      }

 
    }


    do_update = (e) => {
        var order_id = e.currentTarget.dataset.order_id;
        var order_number = e.currentTarget.dataset.order_number;
        var market_id = e.currentTarget.dataset.market_id;
        var data_val = e.currentTarget.dataset.val;
        var data_alarm = e.currentTarget.dataset.alarm;

            $.ajax({
              type: "POST",
              url: "https://rentjoo.com/api/panel/orders/update/stus",
              data: {
               user_Id: localStorage.getItem('user_Username'),
               _token:localStorage.getItem('_token'),
               order_id: order_id,
               val_stus:data_val,
               market_id:market_id,
               order_number:order_number,
               data_alarm:data_alarm
             },
             beforeSend: function () {
                 $(".db_main").addClass("loading");
                 $(".loader_box").show();
          
             },
             success: (res) => {
                  console.log(res);
                 this.componentDidMount();
               
                 }
           });
    
    
      };
    
      do_confirm = (e) => {
          var confirm_stus = e.currentTarget.dataset.confirm;
        if(window.confirm("سفارش " + e.currentTarget.dataset.order_id + " کنسل شود؟ ")){
           this.do_update(e)
        }
    }


    edit_modal = (e) => {
        $(".db_main").addClass("loading");
        $(".loader_box").show();
        
        var get_order_id = e.currentTarget.dataset.order_id;
      
      
          axios.post('https://rentjoo.com/api/panel/orders/edit' ,
          {
                user_Id: localStorage.getItem('user_Username'),
                _token:localStorage.getItem('_token'),
                get_order_id:get_order_id
            })
          .then(res => {
            var api_edit_list = res.data;
              //console.log(api_edit_list);
      
      
              $("#order_modal").addClass('in');
    
      
              $('#update-form-btn,#remove-form-btn ').removeClass('hidden');
              $("#order_modal .modal-title").text("ویرایش سفارش: " + api_edit_list["order_id"] );
              $('#submit_date').text(api_edit_list["date"]).removeClass('hidden');
              //var market_info = api_edit_list["market_name"].split(",");
             // var city_info = api_edit_list["city"].split(",");
              var car_info = api_edit_list["cars_info"].split("/");
              $('#order_id_val').val(api_edit_list["id"]);
              $('#order_val').val(car_info[0]+"/"+car_info[1]);
              $('#color_val').val(car_info[3]);
              $('#model_val').val(car_info[2]);
              $('#count_val').val(api_edit_list["count"]);
              $('#number_val').val(api_edit_list["number"]);
              $('#name_val').val(api_edit_list["name"]);
              $('#price_val').val(api_edit_list["price"]);
              $('#market_val').val(api_edit_list["market_name"]);
              $('#city_val').val(api_edit_list["city"]);
              $('#date_o_val').val(api_edit_list["date_o"]);

              $('#discription_val').val(api_edit_list["discription"]);
      
      
      
              $(".leter_digit").text(Num2persian(api_edit_list["price"]) + " تومان ");
          
      
              $(".db_main").removeClass("loading");
              $(".loader_box").hide();
      
      
          })  
      
      }


      update_form = (e) => {
        var form_color = $('#color_val').val();
        var form_model = $('#model_val').val();
        var form_order = $('#order_val').val();
        form_order = form_order.split("/");
        var form_cars_info  = form_order[0] + "/"+ form_order[1] + "/"+form_model + "/" +form_color ;
        var form_order_id = $('#order_id_val').val();
        var form_market= $('#market_val').val();
        var form_name = $('#name_val').val();
        var form_number = $('#number_val').val();
        var form_city = $('#city_val').val();
        var form_price = $('#price_val').val();
        var form_date_o = $('#date_o_val').val();
        var form_count = $('#count_val').val();
        var form_discription = $('#discription_val').val();
 
     
        
      $.ajax({

        type: "POST",
        url: "https://rentjoo.com/api/panel/orders/update/info",
        data: {
         user_Id: localStorage.getItem('user_Username'),
         _token:localStorage.getItem('_token'),
         form_order_id:form_order_id,
         form_order:form_order[0],
         form_cars_info:form_cars_info,
         form_market:form_market,
         form_name:form_name,
         form_number:form_number,
         form_city:form_city,
         form_price:form_price,
         form_date_o:form_date_o,
         form_count:form_count,
         form_discription:form_discription
       },
        beforeSend: function () {
      
          $(".save_form_btn span").text("درحال ثبت");
          $(".save_form_btn .ico-2").addClass("en");
         // $(".save_form_btn").attr('disabled', 'disabled');
      
      
        },
        success: (res) => {
          $(".save_form_btn span").text("بروز رسانی");
          $(".save_form_btn .ico-2").removeClass("en");
          this.close_modal();
          this.componentDidMount();
          }
      
      });
        
      }


      close_modal = (e) => {
        $('.modal').removeClass('in');
        $('.save_form_btn,.remove_btn').addClass('hidden');
        $('.save_form_btn').addClass('hidden');
      }


      sms_modal = (e) => {
        var user_name = e.currentTarget.dataset.user;
        var user_number = e.currentTarget.dataset.number;
      
      
        $("#sms_modal").addClass('in');
        $("#sms_modal .modal-title").text("ارسال پیامک به " + user_name);
        $("#sms_modal #submit_sms_number").val(user_number);
      
      }
      
      sms_send = (e) => {
        var sms_text = $('#sms_text').val();
        var user_number = $('#submit_sms_number').val();
      
        
        $.ajax({
          type: "POST",
          url: "https://rentjoo.com/api/panel/sms",
          data: {
           user_Id: localStorage.getItem('user_Username'),
           _token:localStorage.getItem('_token'),
           sms_text:sms_text,
           user_number:user_number
         },
        beforeSend: function () {
      
          $(".send_sms span").text("درحال ارسال");
          $(".send_sms .ico-2").addClass("en");
          $(".send_sms").attr('disabled', 'disabled');
      
      
        },
        success: function (result) {
        //alert(result);
        //alertwindow.location.reload(); 
        $('.modal').removeClass('in');
        $(".send_sms").attr('disabled', null);
        $(".send_sms .ico-2").removeClass("en");
        $(".send_sms span").text("ارسال");
        }

    

      });
        
      }



      
    render(){
        document.title = this.props.title;
        $("#rs_head_h1").text(this.props.title);
    
        $(document).ready(function () {
    
            $(".disc_btn").on('click', function () {
              $(".disc_box").hide();
              $(this).parent().find(".disc_box").show();
          
          });
          $(".disc_box .close_b").on('click', function () {
              $(".disc_box").hide();
          
          });
        });
        $("#price_val").on('keyup paste input', function () {

            var price_to_leter = $(this).val();
            $(".leter_digit").text(Num2persian(price_to_leter) + " تومان ");
         
         });
         $(".serch_list_box input").on('keyup paste input', function () {
          var input, filter, ul, li, a,
            i;
          input = $(this).val();
          filter = input.toUpperCase();
      
          a = $(".order_form_box");
          for (i = 0; i < a.length; i++) {
            var txtValue = a[i].textContent ||
              a[i].innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              a[i].style.display = "";
            } else {
              a[i].style.display = "none";
            }
          }
  
        });
      

        return (

<div className="org_content">




            <div className="db_main">

            <div className="head_row">
        <div className="col-sm-6">
          <div className="serch_list_box">
        
            <input type="text" name="search_list" className="search_list_input"  autoComplete="off" placeholder="جستجو سفارش ..." />
    
          </div>
        </div>

        <div className="col-sm-3 pull-left text-left btn_row">
        {  (is_admin===true) &&
        <button type="button"  onClick={this.add_open_modal} className="btn h-add-db-btn" data-toggle="modal" data-target="#user_modal"> <i className="fas fa-clipboard-list"></i>  افزودن سفارش  </button>
        }
         </div>
        
        </div>
   
        <div className="clearfix"></div>
        
       <br/>
       <br/>
<div className="row_0">

<h3 className="head_title hidden">آخرین سفارشات</h3>



<div className="form_sec cars_row row">
            <div className="mb-4 col-xl-12 col-md-12 mb-4 hidden-xs hidden-sm">
                <div className="row">
                    <div className="col-sm-2">
                        <label><i className="fas fa-store"></i> فروشگاه </label>
                    </div>
                    <div className="col-sm-2">
                        <label><i className="fas fa-shopping-cart"></i> سفارش </label>
                    </div>
                    <div className="col-sm-2">
                        <label><i className="fas fa-id-card"></i> مشخصات مشتری  </label>
                    </div>

                    <div className="col-sm-2">
                        <label><i className="fas fa-calendar-alt"></i> تاریخ رزرو </label>
                    </div>
                    <div className="col-sm-1">
                        <label><i className="fa fa-tags"></i> قیمت </label>
                    </div>
                    <div className="col-sm-1">
                        <label><i className="fa fa-bell"></i> وضعیت </label>
                    </div>
                    <div className="col-sm-2">
                        <label><i className="fa fa-edit"></i> عملیات </label>
                    </div>
                </div>
            </div>



{this.state.api_list.map( (order_form_item, index)  =>[

            
<div key={index} className="mb-4 col-xl-12 col-md-12 col-sm-6 col-xs-12  car-list-box order_form_box">
<div className="card shadow order_form_sec">
    <span className="count">{index+1}</span>
    <div className="row2">
    <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12  r-pdd-xs market_sec">
            <div className="in_val"><span className="xs-label">فروشگاه:</span>
             {
               market_info_arr = order_form_item.market_name.split(","),
               market_info_arr[0] 
              }
              <img src={"https://rentjoo.com/api/panel/imgs/?type=market&id=" + market_info_arr[1]} className="img-responsive" />
            <a href={"https://rentjoo.com/tracking/?ref="+ order_form_item.order_id} target="_blank">
          <span className="order_id">{order_form_item.order_id}</span></a>
            </div>
        </div>
         
        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 c-pdd r-pdd-sm r-pdd-xs name_sec">
            <div className="in_val"><span className="xs-label">سفارش:</span> {cars_info_arr = order_form_item.cars_info.split("/"),
             order_form_item.name_form + " | " + cars_info_arr[2] + " | " +cars_info_arr[3]}
            

             {  Number(order_form_item.discription) != " "  && is_admin===true &&
                <>
                     <span className="disc_btn" title="توضیحات"> <i className="fas fa-info-circle"></i></span>
                     <div className="disc_box"> {order_form_item.discription}  <span className="close_b">بستن ×</span></div>
                </>
            
                 }


            </div>
        </div>
       

        
        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 c-pdd-md c-pdd-sm c-pdd-xs name_info_sec">
            <div className="in_val">   <span className="xs-label">نام:</span> {order_form_item.name} <a href={'tel:'+order_form_item.number}> <span className="nubmer_div let_space">{order_form_item.number}</span> </a> </div>
            
        </div>
        {/* <div className="col-lg-1 col-md-2 col-sm-12 col-xs-12 hidden c-pdd-md r-pdd number_sec">
        <div className="in_val let_space"><span className="xs-label">شماره:</span> {order_form_item.number}</div> 
        </div> */}
        <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12 c-pdd-md r-pdd c-pdd-sm c-pdd-xs city_sec">
            <div className="in_val"><span className="xs-label">شهر:</span> { city_split = order_form_item.city.split(","), city_split[0] } - {order_form_item.date_o + " - " + order_form_item.count + " روز " }</div>
        </div>
      

        <div className="col-lg-1 col-md-2 col-sm-12 col-xs-12 c-pdd r-pdd-md price_sec">
            <div className="in_val "><span className="xs-label">اجاره:</span> {formatNumber(order_form_item.price)}<small>  تومان</small> </div>
        </div>

        <div className="col-lg-1 col-md-2 col-sm-12 col-xs-12 c-pdd r-pdd-md r-pdd-sm stus_sec text-right">
     
     
   
        {  Number(order_form_item.stus) === 1 ?
            <div className="in_val stus_1"><span className="xs-label">وضعیت:</span> <i className="fa fa-circle" > </i>بررسی</div>
            : Number(order_form_item.stus) === 2 ?  
            <div className="in_val stus_2"><span className="xs-label">وضعیت:</span> <i className="fa fa-circle" > </i>پیگیری</div>
            : Number(order_form_item.stus) === 9 ?  
            <div className="in_val stus_2"><span className="xs-label">وضعیت:</span> <i className="fa fa-circle" > </i>غیر فعال</div>
            : Number(order_form_item.stus) === 3 ?  
            <div className="in_val stus_3"><span className="xs-label">وضعیت:</span> <i className="fa fa-circle" > </i>تکمیل شده</div>
            : Number(order_form_item.stus) === 6 && 
            <div className="in_val stus_6"><span className="xs-label">وضعیت:</span> <i className="fa fa-circle" > </i>کنسل شده</div>
            
        }

    
            
        </div>

        {  (is_admin===true) &&
        <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12 operate_sec r-pdd r-pdd-sm">
            <div className="in_val">
            <span className="xs-label">عملیات:</span> 
            

            {  (is_admin===true) &&
            <>
            <button className="btns trash ajax_btn" onClick={this.sms_modal} data-user={order_form_item.name} data-number={order_form_item.number}  title="ارسال پیام" ><i className="far fa-envelope"></i></button>
            <button className="btns edit ajax_btn" onClick={this.edit_modal} title="ویرایش" data-order_id={order_form_item.order_id}><i className="far fa-edit"></i></button>
            {  Number(order_form_item.stus) !== 1 &&
            <button className="btns stop ajax_btn" onClick={this.do_update} title="بررسی" data-val="1" data-order_number={order_form_item.number} data-order_id={order_form_item.order_id} data-alarm="false"><i className="far fa-times-circle"></i></button>
             }
            {  (Number(order_form_item.stus) === 1 || Number(order_form_item.stus) === 3) &&

            <button className="btns done ajax_btn" onClick={this.do_update} title="پیگیری" data-val="2"  data-market_id={market_info_arr[1]} data-order_number={order_form_item.number} data-order_id={order_form_item.order_id} data-alarm="false"><i className="far fa-check-circle"></i></button>
           }
            {  Number(order_form_item.stus) === 2 &&
            <button className="btns done ajax_btn" onClick={this.do_update} title="تکمیل شده" data-val="3" data-order_number={order_form_item.number} data-order_id={order_form_item.order_id} data-alarm="false"><i className="fas fa-inbox"></i></button>
           }
            </>
             
            }
            {  Number(order_form_item.stus) !== 6 &&
              <button className="btns trash ajax_btn" onClick={this.do_confirm} title="رد کردن" data-val="6" data-order_number={order_form_item.number} data-order_id={order_form_item.order_id} data-alarm="false"><i className="far fa-trash-alt"></i></button>
            }

            </div>
       
            <button className="btns sms_alarm " onClick={this.alarm_stus} title="ارسال اعلام"> <i className="far fa-bell"></i></button>
        </div>
            }

        
    </div>
</div>
</div>
])}



</div>




</div>


</div>



{  (is_admin===true) &&
<div id="order_modal" className="modal fade users_modal edit_modal" role="dialog">
  <div className="modal-dialog">


    <div className="modal-content">
      <div className="modal-header">
        <button type="button" onClick={this.close_modal}  className="close pull-left" data-dismiss="modal">&times;</button>
        <h4 className="modal-title">ویرایش سفارش </h4>
        <span id="submit_date" className="disabled pull-left submit_date"></span>

      </div>
      <div className="modal-body row">
        <input type="hidden" id="order_id_val" />


      <div className="col-sm-4">
        <div className="form-group">
        <label htmlFor="name_val"><i className="fa fa-id-card"></i> نام مشتری:</label>
        <input type="text" className="form-control" id="name_val" autoComplete="off"  />
        </div>
      </div>


      <div className="col-sm-4">
        <div className="form-group">
        <label htmlFor="number_val"><i className="fa fa-phone-square-alt"></i> شماره:</label>
        <input type="text" className="form-control ltr_dir let_space" id="number_val" autoComplete="off"  />
        </div>
      </div>


      <div className="col-sm-4">
        <div className="form-group">
        <label htmlFor="price_val"><i className="fa fa-store"></i> فروشگاه: </label>
                <select className="form-control" id="market_val">
                    
                {this.state.market_list.map( (market_item_list, index)  =>[
        
                    market_item_list.role != 888 &&
                    <option key={index} value={market_item_list.market_name +","+market_item_list.market_username}>{market_item_list.market_name} | {market_item_list.city}</option>
                    
                    ])}
                    


                </select>

        </div>
      </div>

      


      <div className="col-sm-6">
        <div className="form-group">
        <label htmlFor="price_val"><i className="fa fa-store"></i> سفارش: </label>
                <select className="form-control" id="order_val">
                    
                     {this.state.cars_list.map( (cars_item_list, index)  =>[

                           
                    <option key={index} value={fixNumbers(cars_item_list.title) + '/'+fixNumbers(cars_item_list.id) }>{cars_item_list.title}</option>
                    
                    ])}
                    


                </select>

        </div>
      </div>

        


      
      <div className="col-sm-3">
        <div className="form-group">
        <label htmlFor="price_val"><i className="fa fa-sort-numeric-down"></i> مدل: </label>
        <input type="text" className="form-control ltr_dir let_space turn_number" id="model_val" autoComplete="off"/>
        </div>
      </div>




      <div className="col-sm-3">
        <div className="form-group">
        <label htmlFor="stus_val"><i className="fa fa-palette"></i> رنگ:</label>
          <select className="form-control" id="color_val">

                            <option value="سفید">سفید</option>
                            <option value="مشکی">مشکی</option>
                            <option value="کرم">کرم </option>
                            <option value="زرد">زرد </option>
                            <option value="خاکستری">خاکستری </option>
                            <option value="نقره ای">نقره ای </option>
                            <option value="آبی">آبی </option>
                            <option value="قرمز">قرمز </option>
                            <option value="سبر">سبر</option>
                            <option value="قهوه ای">قهوه ای </option>
                            <option value="سرمه ای">سرمه ای </option>
                            <option value="زرشکی">زرشکی </option>
                            <option value="صورتی">صورتی </option>
                            <option value="بنفش">بنفش </option>
                            <option value="نارنجی">نارنجی </option>
                            <option value="شیری">شیری </option>
                            <option value="بژ">بژ </option>
                            <option value="لجنی">لجنی </option>
                            <option value="فیروزه ای">فیروزه ای </option>
          </select>

        </div>
      </div>




      <div className="col-sm-3">
        <div className="form-group">
        <label htmlFor="city_val"><i className="fa fa-map-marker-alt"></i> شهر:</label>
          <select className="form-control" id="city_val">
          {stok_city_arr.map((item,index) => (
            <option key={index} value={item[0]+","+item[1]}>{item[0]}</option>
            ))}
          </select>

        </div>
      </div>

        
      

      <div className="col-sm-3">
        <div className="form-group">
        <label htmlFor="price_val"><i className="fa fa-calendar-alt"></i> تاریخ رزرو: </label>
        <input type="text" className="form-control ltr_dir let_space turn_number" id="date_o_val" autoComplete="off"/>
        </div>
      </div>


      <div className="col-sm-2">
        <div className="form-group">
        <label htmlFor="stus_val"><i className="fa fa-moon"></i> مدت: </label>
          <select className="form-control" id="count_val">

                            <option value="1">1 روز</option>
                            <option value="2">2 روز</option>
                            <option value="3">3 روز</option>
                            <option value="4">4 روز</option>
                            <option value="5">5 روز</option>
                            <option value="6">6 روز</option>
                            <option value="7">7 روز</option>
                            <option value="8">8 روز</option>
                            <option value="9">9 روز</option>
                            <option value="10">10 روز</option>
     
             
          </select>

        </div>
      </div>


      <div className="col-sm-4">
        <div className="form-group">
        <label htmlFor="price_val"><i className="fa fa-tags"></i> قیمت کل اجاره    
                <small> (تومان): </small>   </label>
        <input type="text" className="form-control ltr_dir let_space turn_number" id="price_val" autoComplete="off"/>
        <div className="leter_digit">صفر تومان</div>
        </div>
      </div>



      <div className="clearfix"></div>
      <hr/>

      <div className="col-sm-12">
      <div className="form-group">
        <label htmlFor="price_val"><i className="fa fa-list-alt"></i>   توضیحات: <small> ( فقط برای مدیریت): </small> </label>
        <textarea className="classic_textarea" placeholder="توضیحات، تغیرات و یا شرح سفارش ..."  id="discription_val" cols="30" rows="4"></textarea>
        </div>
      </div>

      

      </div>
      <div className="modal-footer">


         <button className="btn submit save_form_btn hidden" id="update-form-btn" onClick={this.update_form}  > <span> بروزرسانی</span> <i className="fa fa-cog fa-spin ico-2"></i> </button>
         <button className="btn submit save_form_btn hidden" id="save-form-btn" onClick={this.submit_form}  > <span> ثبت</span> <i className="fa fa-cog fa-spin ico-2"></i> </button>
        
        <button type="button"  onClick={this.close_modal} className="close_modal" id="close_modal" >انصراف</button>
      </div>
    </div>

  </div>
</div>
}



{  (is_admin===true) &&
<div id="sms_modal" className="modal fade sms_modal" role="dialog">
  <div className="modal-dialog">


    <div className="modal-content">
      <div className="modal-header">
        <button type="button" onClick={this.close_modal}  className="close pull-left" data-dismiss="modal">&times;</button>
        <h4 className="modal-title">ارسال پیامک</h4>
                  
        <input type="text" className="disabled pull-left submit_number" id="submit_sms_number" disabled/>



      </div>
      <div className="modal-body row">
                  
        <textarea className="classic_textarea" placeholder="متن پیام ...."  id="sms_text" cols="30" rows="5"></textarea>

      </div>
      <div className="modal-footer">

         <button className="btn submit send_sms " id="send_sms_btn" onClick={this.sms_send}  > <span> ارسال</span> <i className="fa fa-cog fa-spin ico-2"></i> </button>
         <button type="button"  onClick={this.close_modal} className="close_modal" id="close_modal" >انصراف</button>

      </div>
    </div>

  </div>
</div>
}





</div>

        
    )

}
}







