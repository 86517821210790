import React from 'react';
import Page_head from './Page_head';
import Dashboard from './Dashboard';
import Cars from './Cars';
import Add from './Add';
import Profile from './Profile';
import Users from './Users';
import Err404 from './Err404';
import Tools from './Tools';

import { BrowserRouter , Route , Switch } from 'react-router-dom';
import ReactDOM from 'react-dom';

let is_admin = false;
if(localStorage.getItem('user_Role') == 888){
    is_admin = true;
  }



export default class Main extends React.Component {



    render() {
    return (

<div className="right-side dash-page in-page">
<div className="loader_box" ><i className="fa fa-cog fa-spin ico-2"></i> <span> لطفا صبر کنید ...</span> </div>
<Page_head  />   

                                        <Switch>

                                         <Route path="/"  exact  render={(props) => <Dashboard title="سفارشات" {...props} />} />
                                        <Route path="/Cars" render={(props) => <Cars title="ماشین ها" {...props} />} /> 
                                        <Route path="/Add" render={(props) => <Add title="افزودن خودرو" {...props} />} /> 
                                        <Route path="/Profile" render={(props) => <Profile title="حساب کاربری" {...props} />} /> 
                                        {  is_admin == true && 
                                            <>
                                            <Route path="/Users" render={(props) => <Users title="کاربران" {...props} />} /> 
                                            <Route path="/Tools" render={(props) => <Tools title="ابزار ها" {...props} />} /> 
                                            </>
                                        }          

                                        <Route path="/404" render={(props) => <Err404 title="صفحه یافت نشد" {...props} />} /> 
                                        <Route render={(props) => <Err404 title="صفحه یافت نشد" {...props} />} /> 


                                        </Switch>
                                   

            </div>
)

}


}


