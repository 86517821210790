import React, { Component } from 'react';
import $ from "jquery";



export default class Login extends React.Component {


  
  state = {

  }



  do_send = (e) => {
 
    var number_val = $("#number_val").val();
    var password_val = $("#password_val").val();
    var md5_pass = require('md5');
    password_val = md5_pass(password_val);




      $.ajax({
        type: "POST",
        url: "https://rentjoo.com/api/panel/login",
        data: {
          user_number: number_val,
          user_password:password_val,
        },
        beforeSend: function () {
        $(".load_login").removeClass("hidden");

        },
        success: (res) => {

          var result = res;


          if(result.login === false){
            localStorage.clear();
            alert("شماره و یا رمز عبور اشتباه است");
            $(".load_login").addClass("hidden");
            }
            else{
             // console.log(result["id"]);
              localStorage.setItem('user_Name', result["market_name"])
              localStorage.setItem('user_Num', result["market_phone"])
              localStorage.setItem('user_Username', result["market_username"])
              localStorage.setItem('_token', result["_token"])
              localStorage.setItem('user_Avatar', result["avatar"])
              localStorage.setItem('user_Role', result["role"])
              window.location.reload(); 
              return false;
            }
            
            },
            
      });


  };



  render() {

    var
    persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
    arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
    fixNumbers = function (str)
    {
      if(typeof str === 'string')
      {
        for(var i=0; i<10; i++)
        {
          str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
        }
      }
      return str;
    };
    
    $(".turn_number").on('change keydown keyup paste input', function () {
      var turn_number  = $(this).val();
      $(this).val(fixNumbers(turn_number));
      });
    
      $("body").keypress(function(event) {
        if (event.keyCode === 13) {
          $(".btn_submit").click();

        }
      });


    return (

      <div className="login_sec login_page">





<div className="container">
        <div className="row">
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8 login-box">
                <div className="col-lg-12 login-key">
                  
                    <img src="img/logo.png"  />
                    
                </div>
                <div className="col-lg-12 login-title">
                    پنل رنتجو
                </div>

                <div className="col-lg-12 login-form">
                    <div className="col-lg-12 login-form">
                       
                            <div className="form-group">
                                <label className="form-control-label">شماره موبایل:</label>
                                <input id="number_val" type="text" className="form-control ltr_dir turn_number " />
                            </div>
                            <div className="form-group">
                                <label className="form-control-label">رمز عبور:</label>
                                <input id="password_val" type="password" className="form-control ltr_dir" />
                            </div>

                            <div className="col-lg-12 loginbttm">
                     
                                <div className="col-lg-12 login-btm login-button">
                                    <button type="submit"  onClick={this.do_send} className="btn_submit">  ورود <i className="fas fa-spinner fa-pulse load_login hidden"></i></button>
                                </div>
                            </div>
                        
                    </div>
                </div> 
               
              
                <div className="p_log_info">در صورت نیاز به پشتیبانی سیستم از طریق شماره  <b className="let_space"> ( 09026503372 ) </b> اقدام نمایید.</div>
            </div>
        </div>

 </div>


  </div>


    )
  }
}

