import React, { Component } from 'react';
import axios from 'axios';
import $ from "jquery";
import FileBase64 from 'react-file-base64';

let is_admin = false;
if(localStorage.getItem('user_Role') == 888){
    is_admin = true;
  }

  function do_exit(e){
    localStorage.clear();
    window.location.reload(); 
    
  }
  var
  persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  fixNumbers = function (str)
  {
    if(typeof str === 'string')
    {
      for(var i=0; i<10; i++)
      {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  };

export default class Profile extends React.Component {

    
    constructor() {
        super()
        this.state = {
            api_list: [],
            files: []
        }
    }
    
    // Callback~
    getFiles(files){
        this.setState({ files: files })
       
        var file_size = files["size"].replace(/\D/g, "");

       // console.log(files["size"])
     
      if(Number(file_size) > 300){
        alert("حداکثر سایز مجاز فایل 300 کیلیوبایت میباشد.");

      }
      else{

        var post_data={
          action_type: "update_avatar",
          user_Id: localStorage.getItem('user_Username'),
          _token:localStorage.getItem('_token'),
          avatar:this.state.files['base64']
        }

       $.ajax({
        type: "POST",
        url: "https://rentjoo.com/api/panel/user/profile/update",
          
           data: post_data,
           beforeSend: function () {
               $(".db_main").addClass("loading");
               $(".loader_box").show();
       
           },
           success: (res) => {
             console.log(res);
             localStorage.setItem('user_Avatar',post_data["avatar"])
             $(".profile_sec img").attr('src',post_data["avatar"]);
            // window.location.reload(); 
            
              this.componentDidMount();
            
               }
         });
 

      }


        





    }
    
    componentDidMount() {
        
  
      $(".db_main").addClass("loading");
      $(".loader_box").show();
      axios.post('https://rentjoo.com/api/panel/user/profile/info' ,
      {
            user_Id: localStorage.getItem('user_Username'),
            _token:localStorage.getItem('_token')
        })
        .then(res => {
          const api_list = res.data;
          this.setState({ api_list });
          console.log(api_list);
          $(".db_main").removeClass("loading");
          $(".loader_box").hide();
        })
  
    }
  
    open_input = (e) => {
        $('#change_avatar_sec input').trigger('click');

    }




   

    
    submit_change_pass = (e) => {
      var input_curent_pass = $('#curent_pass').val();
      var input_new_pass = fixNumbers($('#new_pass').val());
      var input_new_pass_confrim = fixNumbers($('#new_pass_confrim')).val();
      var letterNumber = /[a-zA-Z]+/;
      var letterNumber2 = /[\s]+/;


      
      if(input_curent_pass === ""){
        alert("رمز عبور قبلی را وارد نمایید.");

      }
      else if(!input_new_pass.match(letterNumber) || input_new_pass.match(letterNumber2) || input_new_pass.length <8){
          alert("رمز عبور جدید باید حداقل 8 رقم وشامل حروف انگلیسی و بدونه فاصله باشد.");

      }
      else if(input_new_pass !== input_new_pass_confrim){
        alert("رمز عبور جدید با تکرار آن برابر نمیباشد.");

      }

      else{
        var md5_pass = require('md5');
        input_new_pass = md5_pass(input_new_pass);
        input_curent_pass = md5_pass(input_curent_pass);
      
          var post_data={
            action_type: "update_pass",
            user_Id: localStorage.getItem('user_Username'),
            _token:localStorage.getItem('_token'),
            user_pass:input_curent_pass,
            new_pass: input_new_pass
   
          }
          $.ajax({
            type: "POST",
            url: "https://rentjoo.com/api/panel/user/profile/update",
           
            data: post_data,
            beforeSend: function () {
               $(".db_main").addClass("loading");
               $(".loader_box").show();
        
            },
            success: (res) => {
             // console.log(res);
            localStorage.setItem('user_Pass',input_new_pass)
             // window.location.reload(); 
             alert("رمز عبور شما با موفقیت تغیر کرد.");
             window.location.reload(); 
        
             
                }
          });
        }
  }
    



  
    
    render() {
      $(document).ready(function () {
      $("#change_avatar_sec input").attr("accept","image/png, image/jpeg");
      $(".turn_number").on('change keydown keyup paste input', function () {
     
        });
      



      });
        $('#file').trigger('click');

        document.title = this.props.title;
        $("#rs_head_h1").text(this.props.title);


    return (


        <div className="org_content">



        <div className="profile_page db_main">
            


        <div  className="col-md-12 user_info_sec text-center">
            <div className="img_sec" onClick={this.open_input}>
        <span className="edit_avatart">تغییر تصویر</span>
          <img src={this.state.api_list["avatar"] || "img/user_img.svg"} alt="#" id="avatar_img" />
            </div>
          <div className="text-center market_name">

             {this.state.api_list["market_name"]}
         

            <span className="market_num">{this.state.api_list["market_phone"]}</span>
          </div>



          <hr/>
          <div className="text-center market_info">

        <div className="hidden" id="change_avatar_sec">
          <FileBase64
        multiple={ false }
        onDone={ this.getFiles.bind(this)  } />

          </div>


          <div className="list">
          <i className="fas fa-map-marker-alt"></i> 
          {this.state.api_list["address"]}
            </div>

            <div className="list">
            <i className="fas fa-phone-alt"></i> 
            {
             Number(this.state.api_list["phone"]) !== 0 ?
            this.state.api_list["phone"] 
            : "-"
            }
            </div>

          </div>

          </div>

            
            <div className="clearfix"> </div>
            <hr/>
            <h4>تغیر رمز عبور</h4>
          <div className="change_pass">
           <div className="form_cp_sec">
        <div className="form-group">
            <label htmlFor="curent_pass">رمز عبور فعلی: </label>
            <input type="password" className="form-control" id="curent_pass" autoComplete="off" />
        </div>
        <div className="form-group">
        <label htmlFor="new_pass">رمز عبور جدید: <small>(حداقل 8 رقم شامل عدد و حروف و بدون فاصله )</small> </label>
            <input type="password" onChange={this.new_pass_input} className="form-control" id="new_pass" autoComplete="off" />
        </div>
        <div className="form-group">
        <label htmlFor="new_pass_confrim"> تکرار رمز عبور جدید: </label>
            <input type="password" className="form-control" id="new_pass_confrim" autoComplete="off" />
        </div>
           </div>

          
          <button type="button"  onClick={this.submit_change_pass} className="submit_form">ثبت</button>
            


          </div>

        <button className="logout_btn visible-xs" onClick={do_exit} ><i className="fas fa-sign-out-alt"></i> <span> خروج از پنل</span></button>
        </div>


        </div>
)

}


}