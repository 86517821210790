import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactDOM from 'react-dom';

let is_admin = false;
if(localStorage.getItem('user_Role') == 888){
    is_admin = true;
  }

function do_exit(e){
  localStorage.clear();
  window.location.reload(); 
  
}

function Sidebar() {
    return (
      <>
      <div className="mobile_bar_sec visible-xs">

             <ul>
             <li> 
               <NavLink className="nav-link" exact to="/">
               <i className="fas fa-fw fa-tachometer-alt"></i> <span>سفارشات</span> 
                </NavLink>
              </li>
              <li> 
               <NavLink className="nav-link"  to="/Cars">
               <i className="fas fa-fw fa-car"></i> <span>ماشین ها</span> 
                </NavLink>
              </li>
              <li> 
               <NavLink className="nav-link"  to="/Profile">
               <i className="fas fa-fw fa-list-alt"></i> <span>پروفایل</span> 
                </NavLink>
              </li>

              {  is_admin === true ?

            <li> 
            <NavLink className="nav-link"  to="/Users">
            <i className="fas fa-fw fa-users"></i>   <span>کاربران</span> 
              </NavLink>
            </li>
                :

                            <li> 
                            <a className="nav-link"  href="https://wa.me/message/UVNRX4GHPFISP1" target="_blank">
                         <i className="fas fa-fw fa-users"></i>   <span>پشتیبانی</span> 
                             </a>
                           </li>

              }
              </ul>
        </div>

        

        <div className="left-side">

  
        <nav className="main-nav">
  
          <div className="logo-sec"> <img src="img/logo.png" alt="" /> <b> پنل رنتجو </b></div>
  
  
          <ul className="menu-nav">
            <li> 
            <NavLink className="nav-link" exact to="/">
              
            <i className="fas fa-fw fa-tachometer-alt"></i><span>سفارشات</span> 
              </NavLink>
            </li>
  

            <li> 
            <NavLink className="nav-link" to="/Cars">
              <i className="fas fa-fw fa-car"></i><span>ماشین ها</span> 
              </NavLink>
              </li>
  


            <li> 
            <NavLink className="nav-link" to="/Profile">
              <i className="fas fa-fw fa-list-alt"></i><span> پروفایل</span>
              </NavLink>
            </li>
            {  is_admin === true &&
      
              <>
            <li> 
            <NavLink className="nav-link" to="/Users">
              <i className="fas fa-fw fa-users"></i><span> کاربران</span>
              </NavLink>
            </li>
  
              <li> 
              <NavLink className="nav-link" to="/Tools">
                <i className="fas fa-fw fa-tools"></i><span> ابزار ها</span>
                </NavLink>
              </li>
              </>
           }
          </ul>
  
  
          <div className="bottom-box">
     
            <div><a className="logout-link" onClick={do_exit} href="#"><i className="fas fa-sign-out-alt"></i> <span> خروج</span></a></div>
  
              {/* <div className="ver">ver:0.1.0</div> */}
          </div>
  
  
        </nav>
  
  
  
      </div>
      </>

)

}




export default Sidebar;



