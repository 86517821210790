import React, { Component } from 'react';

export default class Err404 extends React.Component {



  render() {
    document.title = this.props.title;


  return (


    <div className="page_404">
      <h2>404</h2>
      <h3>صفحه یافت نشد</h3>

      <a href="/">برگشت <i className="fas fa-arrow-circle-left"></i></a>

    </div>



      

)
}
}
