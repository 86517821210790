import React, { Component } from 'react';
import $ from "jquery";
import axios from 'axios';

export default class Tools extends React.Component {




  do_update_cars_db = (e) => {

    $.ajax({
      type: "POST",
      url: "https://rentjoo.com/api/panel/tools/update_stok_cars",
      data: {
        action_type: "update_stock_cars",

      },

    });
    alert("انجام شد");


  }


  render() {
    document.title = this.props.title;
    $("#rs_head_h1").text(this.props.title);


  return (

<div className="org_content">
    <div className="tools_page">

      <div className="col-sm-6 col-md-4">
        <div className="card shadow box_sec text-center">

        <h4> بروزرسانی دیتابیس ماشین ها</h4>
        <button className="btns" onClick={this.do_update_cars_db}> بروزرسانی  <i className="fas fa-sync-alt"></i> </button>
        </div>
      </div>

    </div>
</div>



      

)
}
}
