import React, { Component } from 'react';
import axios from 'axios';
import $ from "jquery";
import Num2persian from 'num2persian';
import { NavLink } from 'react-router-dom';

let is_admin = false;
let cars_list_filter = false;
let filter_city_val;

if(localStorage.getItem('user_Role') == 888){
    is_admin = true;
  }
  let cars_city_arr = new Array();
  var cars_city_arr2;
  const stok_city_arr = [["آبادان","abadan"],["اراک","arak"],["اردبیل","ardabil"],["ارومیه","urmia"],["اصفهان","isfahan"],["ایلام","ilam"],["اهواز","ahvaz"] ,["بجنورد","bojnurd"],["بندرعباس","bandar-abbas"],["بوشهر","bushehr"],["بیرجند","birjand"],["تبریز","tabriz"],["تهران","tehran"],["خرم آباد","khorramabad"],["رشت","rasht"],["زاهدان","zahedan"],["زنجان","zanjan"],["ساری","sari"],["سنندج","sanandaj"],["شهرکرد","shahrekord"],["شیراز","shiraz"],["قزوین","qazvin"],["قشم","qeshm"],["قم","qom"],["کرج","karaj"],["کرمان","kerman"],["کرمانشاه","kermanshah"],["کیش","kish"],["گرگان","gorgan"],["مشهد","mashhad"],["یاسوج","yasuj"],["یزد","yazd"]];



  var
  persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  fixNumbers = function (str)
  {
    if(typeof str === 'string')
    {
      for(var i=0; i<10; i++)
      {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  };

export default class Add extends React.Component {
  state = {
    market_list: [],
    cars_list: []

  }


  componentDidMount() {


    if( is_admin === true){

      axios.post('https://rentjoo.com/api/panel/user/market/list' ,{
        user_Id: localStorage.getItem('user_Username'),
        _token:localStorage.getItem('_token')
    })
      .then(res => {
        const market_list = res.data;
        this.setState({ market_list });
      })
    }
    


    axios.post('https://rentjoo.com/api/panel/cars/stocks' ,{
          user_Id: localStorage.getItem('user_Username'),
          _token:localStorage.getItem('_token')
      })
    .then(res => {
    //  var myObject = JSON.parse(res.data) ;
      const cars_list = res.data;
      this.setState({ cars_list });

   
    })


    
    }
    

    cars_stock_get = (e) => {
      this.filter_city_val = e.currentTarget.dataset.filter;
      
 
      this.setState({ cars_list : this.state.cars_list });
  
      
  
    }
  

  save_new_car = (e) => {
 


    var split_car_info = $("#cars_val_input").val();
    split_car_info =  split_car_info.split("/");
    var val_save_market_id = $("#market_id_val_input").val();
    var val_save_city = $("#city_val_input").val();
    var val_save_model = $("#model_val_input").val();
    var val_save_price = $("#price_val_input").val();
    var val_save_color = $("#color_val_input").val();
    var val_save_extra = $("#extra_val_input").val();
    
    var check_valid= true;
    
    $(".alert_box").html("").hide();
    if( is_admin === true && val_save_market_id === ""){
      $(".alert_box").append("<div class='alert_list'>  فروشنده را انتخاب نمایید. </div>").fadeIn(200);
      check_valid= false;
    }
    if (val_save_city === "") {
      $(".alert_box").append("<div class='alert_list'>شهر مورد تحویل خودرو را انتخاب نمایید. </div>").fadeIn(200);
      check_valid= false;
    }
    if (split_car_info[0] === "") {
      $(".alert_box").append("<div class='alert_list'>نام خودرو را انتخاب نمایید. </div>").fadeIn(200);
      check_valid= false;
    }
    if (val_save_model === "") {
      $(".alert_box").append("<div class='alert_list'>مدل دقیق خودرو را انتخاب نمایید. </div>").fadeIn(200);
      check_valid= false;
    }
  
    if (val_save_color === "") {
      $(".alert_box").append("<div class='alert_list'>رنگ خودرو را انتخاب نمایید. </div>").fadeIn(200);
      check_valid= false;
    }
  
    if (val_save_price === "") {
      $(".alert_box").append("<div class='alert_list'>قیمت خودرو را انتخاب نمایید. </div>").fadeIn(200);
      check_valid= false;
    }
  

  if(check_valid === true){
  
  
    $.ajax({
      type: "POST",
      url: "https://rentjoo.com/api/panel/cars/add",
      data: {
       user_Id: localStorage.getItem('user_Username'),
       _token:localStorage.getItem('_token'),
       save_city: val_save_city,
       save_car_id: split_car_info[0],
       save_car_type: split_car_info[1],
       save_car_brand: split_car_info[2],
       save_car_name: split_car_info[3],
       save_market_id: val_save_market_id,
       save_color: val_save_color,
       save_model: val_save_model,
       save_price: val_save_price,
       save_extra: val_save_extra,
     },
      beforeSend: function () {
  
        $(".save_form_btn span").text("درحال ثبت");
        $(".save_form_btn .ico-2").addClass("en");
        $(".save_form_btn").attr('disabled', 'disabled');
  
  
      },
      success: function (result) {
  //alert(result);
        //location.replace(result)
  
        $(".form_sec").addClass("hidden");
        $(".done_box").removeClass("hidden").hide().fadeIn(200);
      }
    });

  
  }
  


  };










  render() {
    document.title = this.props.title;
    $("#rs_head_h1").text(this.props.title);
    $(document).ready(function () {




  
  $(".turn_number").on('change keydown keyup paste input', function () {
    var turn_number  = $(this).val();
    $(this).val(fixNumbers(turn_number));
    });
  





      $("#price_val_input").on('keyup paste input', function () {

        var price_to_leter = $(this).val();
        $(".leter_digit").text(Num2persian(price_to_leter) + " تومان ");
     
     });


    $(".close_dr").on('click', function () {
      $(".dropdown-content").removeClass("show");
    });
    $(".drop_select .dropbtn").on('click', function () {
    
      $(".dropdown-content").removeClass("show");
      $(this).parent().find(".dropdown-content").toggleClass("show");
    });


    $("#city_drop_select .dropdown-content li").on('click', function () {

      $(".drop_select .selected_vals").removeClass("disabled");
      $("#cars_val_input").val("");
      $("#car_select_btn_lab").text("انتخاب کنید");
      

  });



  $("#car_drop_select .dropdown-content li").on('click', function () {
    var select_car_img = $(this).data("img");

 
    $("#img_select_car").hide().attr("src",select_car_img).fadeIn(100);

    

});



    $(".drop_select .dropdown-content li").on('click', function () {
      
      var copy_selected_text = $(this).text();
      var copy_selected_val = $(this).data("val");
      $(this).parent().parent().parent().find(".selected_vals").addClass("done").text(copy_selected_text);
      $(this).parent().parent().parent().find(".input_store").val(copy_selected_val);
      $(this).parent().parent().removeClass("show");

  });

 


  $(".drop_select .search_input").on('keyup paste input', function () {
    var input, filter, ul, li, a,
      i;
    input = $(this).val();
    filter = input.toUpperCase();

    a = $(this).parent().find("li");
    for (i = 0; i < a.length; i++) {
      var txtValue = a[i].textContent ||
        a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  });




  
  
  











});

        $("#price_val").on('keyup paste input', function () {

        var price_to_leter = $(this).val();
        $(".leter_digit").text(Num2persian(price_to_leter) + " تومان ");
     
     });


    return (




<div className="org_content">


<div className="done_box hidden">
  <span className="done_icon">

    <i className="fa fa-check-circle"></i>
  </span>

خودرو جدید با موفقیت ثبت شد 
<br/>
و پس از تایید تیم پشتیبانی رنتجو،
در پنل شما قابل استفاده می باشد.

<div className="row link_row">

  <div className="col-sm-6"><a className="add_again" href="/Add">افزودن خودرو جدید</a></div>
  <div className="col-sm-6"><NavLink className="back_home" to="/cars">برشگت به لیست <i className="fa fa-arrow-circle-left"></i></NavLink></div>




</div>

</div>
<div className="form_sec row">
{  is_admin === true ?

    <>
    <div className="mb-4 col-lg-12 col-md-12 mb-4 admin_vis">
        <div className="card shadow form_city_sec">
            <div className="form-group drop_select" >
                <label ><i className="fa fa-users"></i> فروشگاه: </label>
                <div className="dropdown">
                    <button className="dropbtn selected_vals">انتخاب کنید</button>
                    <input id="market_id_val_input" className="input_store" type="hidden" />
                    <div id="market_id_val_input_myDropdown" className="dropdown-content">
                        <span className="close_dr"><i className="fa fa-window-close"></i></span>
                        <input className="search_input" type="text" placeholder="جستجو سریع ..." autoComplete="off" />
                        <ul>
                      
          {this.state.market_list.map( (market_item_list, index)  =>[

              market_item_list.role != 888 &&
  
                <li key={index} data-val={market_item_list.market_name +","+market_item_list.market_username}> {market_item_list.market_name} </li>
              
                  
          ])}
          

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="clearfix"></div>
    <br />
    </> :
             <input type="hidden" id="market_id_val_input" name="market_id" value={localStorage.getItem('user_Name')+","+localStorage.getItem('user_Id')} />
        }

   

    <div className="mb-4 col-lg-3 col-md-6 mb-4 c-row_form">
        <div className="card shadow form_city_sec">
            <div className="form-group drop_select" id="city_drop_select">
                <label ><i className="fa fa-map-marker-alt"></i> شهر تحویل: </label>
                <div className="dropdown">
                    <button className="dropbtn selected_vals">انتخاب کنید</button>
                    <input id="city_val_input" className="input_store" type="hidden" />
                    <div id="city_val_input_myDropdown" className="dropdown-content">
                        <span className="close_dr"><i className="fa fa-window-close"></i></span>
                        <input className="search_input" type="text" placeholder="جستجو سریع ..." autoComplete="off" />


                        <ul>
                        {stok_city_arr.map(item => (
                          <li onClick={this.cars_stock_get} data-filter={item[1]} data-val={item[0]+","+item[1]}>{item[0]} </li>
                        ))}

               
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="mb-4 col-lg-3 col-md-6 mb-4 c-row_form">
        <div className="card shadow form_car_sec">
            <div className="form-group drop_select" id="car_drop_select">
                <label ><i className="fa fa-car"></i> نام خودرو: </label>
                <div className="dropdown">
                    <button className="dropbtn selected_vals disabled" id="car_select_btn_lab">انتخاب کنید</button>
                    <input id="cars_val_input" className="input_store" type="hidden" />
                    <div id="cars_val_input_myDropdown" className="dropdown-content">
                        <span className="close_dr"><i className="fa fa-window-close"></i></span>
                        <input className="search_input" type="text" placeholder="جستجو سریع ..." autoComplete="off" />
                
                        <ul>

                                    
      {this.state.cars_list.map( (cars_item_list, index)  =>[
           // cars_city_arr = cars_item_list.city.split(","),
          
           console.assert( cars_city_arr = cars_item_list.city.split(",")),
       //    function myFunction(e) {
             
          
              //  return cars_city_arr = cars_item_list.city.split(",")
          
            // },

       
             
              (cars_city_arr.includes(this.filter_city_val))  &&
              
              
              <li key={index} data-city={cars_item_list.city} data-img={cars_item_list.img} data-val= {cars_item_list.id + '/'+cars_item_list.type + '/' +cars_item_list.brand + '/' + fixNumbers(cars_item_list.title)   } > {cars_item_list.title} </li>
            
  
          ])}
          

                        </ul>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="mb-4 col-lg-3 col-md-6 mb-4 c-row_form">
        <div className="card shadow form_model_sec">
            <div className="form-group drop_select">
                <label ><i className="fa fa-sort-numeric-down"></i> مدل: </label>
                <div className="dropdown">
                    <button className="dropbtn selected_vals disabled">انتخاب کنید</button>
                    <input id="model_val_input" className="input_store" type="hidden" />
                    <div id="model_val_input_myDropdown" className="dropdown-content">
                        <span className="close_dr"><i className="fa fa-window-close"></i></span>
                        <input className="search_input" type="text" placeholder="جستجو سریع ..." autoComplete="off" />
                        <ul className="car_drop">
                            <li className="col-sm-4 disabled" data-val="2021" disabled>میلادی</li> <li className="col-sm-4 disabled"> # </li><li className="col-sm-4 pull-left disabled" data-val="1400" disabled>شمسی</li>
                      
                            <li className="col-sm-4" data-val="2021">2021</li><li className="col-sm-4 pull-left" data-val="1400">1400</li>
                            <li className="col-sm-4" data-val="2020">2020</li><li className="col-sm-4 pull-left" data-val="1399">1399</li>
                            <li className="col-sm-4" data-val="2019">2019</li><li className="col-sm-4 pull-left" data-val="1398">1398</li>
                            <li className="col-sm-4" data-val="2018">2018</li><li className="col-sm-4 pull-left" data-val="1397">1397</li>
                            <li className="col-sm-4" data-val="2017">2017</li><li className="col-sm-4 pull-left" data-val="1396">1396</li>
                            <li className="col-sm-4" data-val="2016">2016</li><li className="col-sm-4 pull-left" data-val="1395">1395</li>
                            <li className="col-sm-4" data-val="2015">2015</li><li className="col-sm-4 pull-left" data-val="1394">1394</li>
                            <li className="col-sm-4" data-val="2014">2014</li><li className="col-sm-4 pull-left" data-val="1393">1393</li>
                            <li className="col-sm-4" data-val="2013">2013</li><li className="col-sm-4 pull-left" data-val="1392">1392</li>
                            <li className="col-sm-4" data-val="2012">2012</li><li className="col-sm-4 pull-left" data-val="1391">1391</li>
                            <li className="col-sm-4" data-val="2011">2011</li><li className="col-sm-4 pull-left" data-val="1390">1390</li>
                            <li className="col-sm-4" data-val="2010">2010</li><li className="col-sm-4 pull-left" data-val="1389">1389</li>
                            <li className="col-sm-4" data-val="2009">2009</li><li className="col-sm-4 pull-left" data-val="1388">1388</li>
                            <li className="col-sm-4" data-val="2008">2008</li><li className="col-sm-4 pull-left" data-val="1387">1387</li>
                            <li className="col-sm-4" data-val="2007">2007</li><li className="col-sm-4 pull-left" data-val="1386">1386</li>
                            <li className="col-sm-4" data-val="2006">2006</li><li className="col-sm-4 pull-left" data-val="1385">1385</li>
                            <li className="col-sm-4" data-val="2005">2005</li><li className="col-sm-4 pull-left" data-val="1384">1384</li>
                            <li className="col-sm-4" data-val="2004">2004</li><li className="col-sm-4 pull-left" data-val="1383">1383</li>
                            <li className="col-sm-4" data-val="2003">2003</li><li className="col-sm-4 pull-left" data-val="1382">1382</li>
                            <li className="col-sm-4" data-val="2002">2002</li><li className="col-sm-4 pull-left" data-val="1381">1381</li>
                            <li className="col-sm-4" data-val="2001">2001</li><li className="col-sm-4 pull-left" data-val="1380">1380</li>
                            <li className="col-sm-4" data-val="2000">2000</li><li className="col-sm-4 pull-left" data-val="1379">1379</li>
                            <li className="col-sm-4" data-val="1999">1999</li><li className="col-sm-4 pull-left" data-val="1378">1378</li>
                            <li className="col-sm-4" data-val="1998">1998</li><li className="col-sm-4 pull-left" data-val="1377">1377</li>
                            <li className="col-sm-4" data-val="1997">1997</li><li className="col-sm-4 pull-left" data-val="1376">1376</li>
                            <li className="col-sm-4" data-val="1996">1996</li><li className="col-sm-4 pull-left" data-val="1375">1375</li>
                            <li className="col-sm-4" data-val="1995">1995</li><li className="col-sm-4 pull-left" data-val="1374">1374</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="mb-4 col-lg-3 col-md-6 mb-4 c-row_form">
        <div className="card shadow form_color_sec">
            <div className="form-group drop_select">
                <label ><i className="fa fa-palette"></i> رنگ: </label>
                <div className="dropdown">
                    <button className="dropbtn selected_vals disabled">انتخاب کنید</button>
                    <input id="color_val_input" className="input_store" type="hidden" />
                    <div id="color_val_input_myDropdown" className="dropdown-content">
                        <span className="close_dr"><i className="fa fa-window-close"></i></span>
                        <input className="search_input" type="text" placeholder="جستجو سریع ..." autoComplete="off" />
                        <ul>
                            <li data-val="سفید">سفید <span style={{ background: '#fff'}}></span></li>
                            <li data-val="مشکی">مشکی <span style={{ background: '#000'}}></span></li>
                            <li data-val="کرم">کرم <span style={{ background: 'bisque'}}></span></li>
                            <li data-val="زرد">زرد <span style={{ background: '#FFEB3B'}}></span></li>
                            <li data-val="خاکستری">خاکستری <span style={{ background: 'gray'}}></span></li>
                            <li data-val="نقره ای">نقره ای <span style={{ background: 'lightgrey'}}></span></li>
                            <li data-val="آبی">آبی <span style={{ background: '#0c86e7'}}></span></li>
                            <li data-val="قرمز">قرمز <span style={{ background: 'red'}}></span></li>
                            <li data-val="سبر">سبر <span style={{ background: 'green'}}></span></li>
                            <li data-val="قهوه ای">قهوه ای <span style={{ background: 'brown'}}></span></li>
                            <li data-val="سرمه ای">سرمه ای <span style={{ background: 'navy'}}></span></li>
                            <li data-val="زرشکی">زرشکی <span style={{ background: 'crimson'}}></span></li>
                            <li data-val="صورتی">صورتی <span style={{ background: 'pink'}}></span></li>
                            <li data-val="بنفش">بنفش <span style={{ background: '#9C27B0'}}></span></li>
                            <li data-val="نارنجی">نارنجی <span style={{ background: '#FF9800'}}></span></li>
                            <li data-val="شیری">شیری <span style={{ background: 'lightyellow'}}></span></li>
                            <li data-val="بژ">بژ <span style={{ background: 'mistyrose'}}></span></li>
                            <li data-val="لجنی">لجنی <span style={{ background: 'darkslategray'}}></span></li>
                            <li data-val="فیروزه ای">فیروزه ای <span style={{ background: 'cyan'}}></span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="mb-4 col-lg-3 col-md-6 mb-4 c-row_form">
        <div className="card shadow form_price_sec">
            <div className="form-group ">
                <label ><i className="fa fa-tags"></i> قیمت اجاره یک شب  
                <small> (تومان) </small>   
                    
                    </label>
                <input id="price_val_input" className="input_store classic_input turn_number ltr_dir let_space" type="text"
                    autoComplete="off" />
                <div className="leter_digit">صفر تومان</div>
            </div>
        </div>
    </div>
    <div className="mb-4 col-lg-6 col-md-6 mb-4 c-row_form">
        <div className="card shadow form_extra_sec">
            <div className="form-group ">
                <label ><i className="fa fa-list-alt"></i> توضیحات: </label>
                <textarea className="classic_textarea" placeholder="توضیحات یا ویژگی های خودرو... (اختیاری)" name=""
                    id="extra_val_input" cols="30" rows="4"></textarea>
            </div>
        </div>
    </div>

    <div className="mb-4 col-lg-3 col-md-6 mb-4 c-row_form">
        <div className=" form_price_sec cars_select_img text-center">
          <img id="img_select_car" src="#" />
        </div>
    </div>
    <div className="clearfix"></div>

    <div className="alert_box"></div>
    <div className="clearfix"></div>
    <button className="save_form_btn" onClick={this.save_new_car} id="add_car_save_btn"> <span> ثبت</span> <i className="fa fa-cog fa-spin ico-2"></i> </button>



</div>
</div>
    )
  }
}
