import React from 'react';

let is_admin = false;
if(localStorage.getItem('user_Role') == 888){
    is_admin = true;
  }


function Page_head(props) {


    return (

 
<div className="row sec_1 head_sec">
        <div className="col-xs-6 col-sm-5 col-md-4 col-lg-4">

        <h1 id="rs_head_h1"> داشبورد </h1>
   
        </div>

        <div className="col-xs-6 col-sm-5 col-md-8 col-lg-2 pull-left profile_sec">

          <b> {localStorage.getItem('user_Name')}
          {
             is_admin == true ? 
             <span>ادمین</span>
             :<span>شرکت</span>
             

          }
         
          </b>
          <img src={localStorage.getItem('user_Avatar')} className="img-responsive" alt="Image" />
   
        </div>

      </div>


        
    )

}




export default Page_head;



