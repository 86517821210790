import React, { Component } from 'react';
import axios from 'axios';
import $ from "jquery";
import Num2persian from 'num2persian';
import { NavLink } from 'react-router-dom';
const stok_city_arr = [["آبادان","abadan"],["اراک","arak"],["اردبیل","ardabil"],["ارومیه","urmia"],["اصفهان","isfahan"],["ایلام","ilam"],["اهواز","ahvaz"] ,["بجنورد","bojnurd"],["بندرعباس","bandar-abbas"],["بوشهر","bushehr"],["بیرجند","birjand"],["تبریز","tabriz"],["تهران","tehran"],["خرم آباد","khorramabad"],["رشت","rasht"],["زاهدان","zahedan"],["زنجان","zanjan"],["ساری","sari"],["سنندج","sanandaj"],["شهرکرد","shahrekord"],["شیراز","shiraz"],["قزوین","qazvin"],["قشم","qeshm"],["قم","qom"],["کرج","karaj"],["کرمان","kerman"],["کرمانشاه","kermanshah"],["کیش","kish"],["گرگان","gorgan"],["مشهد","mashhad"],["یاسوج","yasuj"],["یزد","yazd"]];

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

// function city_to_fa(city_data) {
//   var result,result_2,i;

//   function checkCity(city_temp) {
//     return city_temp === city_data;
//   }
//   for (i = 0; i < stok_city_arr.length; i++) {
//     result = stok_city_arr[i].find(checkCity);
//    if (result === city_data) { result_2 = stok_city_arr[i][0]}
// }
// return result_2;
// }

let is_admin = false;
if(localStorage.getItem('user_Role') == 888){
    is_admin = true;
  }

  let city_split;
  let cars_info_arr = new Array();
  let market_info_arr = new Array();
export default class Charts extends React.Component {
  state  = {
    api_list: []
}





componentDidMount() {




    $(".db_main").addClass("loading");
    $(".loader_box").show();
    axios.post('https://rentjoo.com/api/panel/cars/list' ,
    {
          user_Id: localStorage.getItem('user_Username'),
          _token:localStorage.getItem('_token')
      })
      .then(res => {
        const api_list = res.data;
        if(api_list!== null){
          this.setState({ api_list });
        }

        $(".db_main").removeClass("loading");
        $(".loader_box").hide();
      })

  }




  


do_update = (e) => {
 
    var car_id = e.currentTarget.dataset.cars_id;
    var data_val = e.currentTarget.dataset.val;


       $.ajax({
        type: "POST",
        url: "https://rentjoo.com/api/panel/cars/update/stus",
        data: {
         user_Id: localStorage.getItem('user_Username'),
         _token:localStorage.getItem('_token'),
         car_id: car_id,
         val_stus:data_val,
       },
         beforeSend: function () {
             $(".db_main").addClass("loading");
             $(".loader_box").show();
         },
         success: (res) => {
         
             this.componentDidMount();
           
             }
       });


  }


    
  do_confirm = (e) => {
    var confirm_stus = e.currentTarget.dataset.confirm;
  if(window.confirm("از حذف این خودرو اطمینان دارید؟")){
     this.do_update(e)
  }
}



edit_modal = (e) => {
  $(".db_main").addClass("loading");
  $(".loader_box").show();
  
  var get_car_id = e.currentTarget.dataset.cars_id;


    axios.post('https://rentjoo.com/api/panel/cars/edit' ,
    {
          user_Id: localStorage.getItem('user_Username'),
          _token:localStorage.getItem('_token'),
          get_car_id:get_car_id
      })
    .then(res => {
      var api_edit_list = res.data;
        //console.log(api_edit_list);


        $("#car_modal").addClass('in');
        $(".edit_modal .model_val").addClass('hidden');
        if( api_edit_list["model"] > 1900){
          $(".edit_modal .en_model_sel").removeClass('hidden');
        }
        else{
          $(".edit_modal .fa_model_sel").removeClass('hidden');

        }

        $('#update-form-btn,#remove-form-btn ').removeClass('hidden');
        $("#car_modal  .modal-title").text("ویرایش خودرو: " + api_edit_list["name"] + " | " + api_edit_list["model"] );
        $('#submit_date').text(api_edit_list["time_submit"]);


        $('#car_id_val').val(api_edit_list["id"]);
        $('#user_id_val').val(api_edit_list["market_id"]);
        $('#name_val').val(api_edit_list["name"]);
        $('#city_val').val(api_edit_list["city"]);
        $('#color_val').val(api_edit_list["color"]);
        $('#model_val,.model_val').val(api_edit_list["model"]);
        $('#price_val').val(api_edit_list["price"]);
        $('#extra_val').val(api_edit_list["extra"]);




        $(".img_sec_2 img").attr("src",'https://rentjoo.com/api/panel/imgs/?type=cars&id=' + api_edit_list["car_id"]);

        $(".leter_digit").text(Num2persian(api_edit_list["price"]) + " تومان ");
    

        $(".db_main").removeClass("loading");
        $(".loader_box").hide();


    })  

}


update_form = () => {

  var form_car_id = $('#car_id_val').val();
  var form_user_id = $('#user_id_val').val();
  var form_color = $('#color_val').val();
  var form_model = $('#model_val').val();
  var form_city = $('#city_val').val();
  var form_price = $('#price_val').val();
  var form_extra = $('#extra_val').val();



$.ajax({
  type: "POST",
  url: "https://rentjoo.com/api/panel/cars/update/info",
  data: {
   user_Id: localStorage.getItem('user_Username'),
   _token:localStorage.getItem('_token'),
   form_car_id: form_car_id,
   form_user_id: form_user_id,
   form_color: form_color,
   form_city: form_city,
   form_model: form_model,
   form_price: form_price,
   form_extra: form_extra
 },
  beforeSend: function () {

    $(".save_form_btn span").text("درحال ثبت");
    $(".save_form_btn .ico-2").addClass("en");
   // $(".save_form_btn").attr('disabled', 'disabled');


  },
  success: (res) => {
    $(".save_form_btn span").text("بروز رسانی");
    $(".save_form_btn .ico-2").removeClass("en");
    this.close_modal();
    this.componentDidMount();
    }

});
  
}



delete_form = (e) => {

  if(window.confirm("از حذف این خودرو اطمینان دارید؟")){


    var form_car_id = $('#car_id_val').val();
    var form_user_id = $('#user_id_val').val();

    

  $.ajax({
    type: "POST",
    url: "https://rentjoo.com/api/panel/cars/delete",
    data: {
     user_Id: localStorage.getItem('user_Username'),
     _token:localStorage.getItem('_token'),
     form_car_id: form_car_id,
   },
    beforeSend: function () {

      $(".save_form_btn span").text("درحال حذف");
      $(".save_form_btn .ico-2").addClass("en");
     // $(".save_form_btn").attr('disabled', 'disabled');
  
    },
    success: (res) => {
      $(".save_form_btn span").text("بروز رسانی");
      $(".save_form_btn .ico-2").removeClass("en");
      this.close_modal();
      this.componentDidMount();
      }
  });



  }



  }



close_modal = (e) => {
  $('.modal').removeClass('in');
  $('.save_form_btn,.remove_btn').addClass('hidden');
  $('.save_form_btn').addClass('hidden');
}



  render() {
      document.title = this.props.title;
      $("#rs_head_h1").text(this.props.title);


 
    $(document).ready(function () {
    


      $(".model_val").on('change keydown keyup paste input', function () {
        var model_val_temp  = $(this).val();
        $('#model_val').val(model_val_temp);
    
        });



        $(".disc_btn").on('click', function () {
          $(".disc_box").hide();
          $(this).parent().find(".disc_box").show();
      
      });
      $(".disc_box .close_b").on('click', function () {
          $(".disc_box").hide();
      
      });

      var
      persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
      arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
      fixNumbers = function (str)
      {
        if(typeof str === 'string')
        {
          for(var i=0; i<10; i++)
          {
            str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
          }
        }
        return str;
      };
      $(".turn_number").on('change keydown keyup paste input', function () {
        var turn_number  = $(this).val();
        $(this).val(fixNumbers(turn_number));
        });
      

    });
      
    
    $(".serch_list_box input").on('keyup paste input', function () {
        var input, filter, ul, li, a,
          i;
        input = $(this).val();
        filter = input.toUpperCase();
    
        a = $(".car-list-box");
        for (i = 0; i < a.length; i++) {
          var txtValue = a[i].textContent ||
            a[i].innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = "";
          } else {
            a[i].style.display = "none";
          }
        }

      });
    
      $("#price_val").on('keyup paste input', function () {

        var price_to_leter = $(this).val();
        $(".leter_digit").text(Num2persian(price_to_leter) + " تومان ");
     
     });


    return (




<div className="org_content">




    <div className="card-body cars_list_page c-pdd-xs c-pdd-sm">
        <div className="form_sec cars_row row">





            <div className="db_main">



    <div className="head_row">
        <div className="col-sm-6">
          <div className="serch_list_box">
        
            <input type="text" name="search_list" className="search_list_input"  autoComplete="off" placeholder="جستجو خودرو ..."  />
    
          </div>
        </div>

         <div className="col-sm-3 pull-left text-left btn_row">
     
         <NavLink className="btn h-add-db-btn" to="/Add"> <i className="fas fa-fw fa-car"></i> افزودن خودرو </NavLink>

    
         </div>
        
    </div>
   
        <div className="clearfix"></div>
        
       <br/>
       <br/>




            <div className="mb-4 col-xl-12 col-md-12 mb-4 hidden-xs hidden-sm">
                <div className="row">
                    <div className="col-sm-2">
                        <label><i className="fa fa-camera"></i> عکس </label>
                    </div>
                    <div className="col-sm-2">
                        <label><i className="fa fa-car"></i> نام </label>
                    </div>
                    <div className="col-sm-1">
                        <label><i className="fa fa-sort-numeric-down"></i> مدل </label>
                    </div>
                    <div className="col-sm-1">
                        <label><i className="fa fa-palette"></i> رنگ </label>
                    </div>
                    <div className="col-sm-1">
                        <label><i className="fa fa-map-marker-alt"></i> شهر </label>
                    </div>
                    <div className="col-sm-2">
                        <label><i className="fa fa-tags"></i> قیمت </label>
                    </div>
                    <div className="col-sm-1">
                        <label><i className="fa fa-bell"></i> وضعیت </label>
                    </div>
                    <div className="col-sm-2">
                        <label><i className="fa fa-edit"></i> عملیات </label>
                    </div>
                </div>
            </div>




{this.state.api_list.map( (cars_item_list, index)  =>[
            
<div key={index} className="mb-4 col-xl-12 col-md-12 col-sm-6 col-xs-12  car-list-box ">
<div className="card shadow form_city_sec">
    <span className="count">{index+1}</span>

        <div className="col-lg-2 col-md-2 col-sm-5 col-xs-5 img_sec">
             <img className="car_pic_src" src={cars_item_list.src} 
                alt="" />
            
        </div>
        <div className="col-lg-2 col-md-2 col-sm-7 col-xs-7 r-pdd-sm r-pdd-xs name_sec">

            {  (is_admin===true) ?
            <>         
            <div className="in_val in_admin"><span className="xs-label">نام:</span> {cars_item_list.name} </div>
            <span className="market_id"> 
            {market_info_arr = cars_item_list.market_id.split(","), market_info_arr[0]}
            </span>
            </>
            : 
            <div className="in_val"><span className="xs-label">نام:</span> {cars_item_list.name} </div>
            }
            
             {  Number(cars_item_list.extra) != " "  &&
                <>
                     <span className="disc_btn" title="توضیحات"> <i className="fas fa-info-circle"></i></span>
                     <div className="disc_box"> {cars_item_list.extra}  <span className="close_b">بستن ×</span></div>
                </>
            
                 }


           
        </div>
       
        <div className="clearfix visible-xs visible-sm"></div>
        
        <div className="col-lg-1 col-md-1 col-sm-4 col-xs-4 c-pdd-md model_sec">
            <div className="in_val"> <span className="xs-label">مدل:</span>  {cars_item_list.model}</div>
        </div>
        <div className="col-lg-1 col-md-1 col-sm-4 col-xs-4 c-pdd-md c-pdd-sm color_sec">
            <div className="in_val"><span className="xs-label">رنگ:</span> {cars_item_list.color}</div>
        </div>
        <div className="col-lg-1 col-md-1 col-sm-4 col-xs-4 c-pdd-md city_sec">
            <div className="in_val"><span className="xs-label">شهر:</span> {city_split = cars_item_list.city.split(","), city_split[0] }</div>
        </div>
        <div className="clearfix visible-xs visible-sm"></div>

        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 r-pdd-md price_sec">
            <div className="in_val"><span className="xs-label">اجاره:</span> {formatNumber(cars_item_list.price)}<small>  تومان</small> </div>
        </div>

        <div className="col-lg-1 col-md-1 col-sm-6 col-xs-6 r-pdd-md r-pdd-sm c-pdd-lg c-pdd-md stus_sec ">
     
     
   
        {  Number(cars_item_list.stus) === 1 ?
            <div className="in_val stus_1"><span className="xs-label">وضعیت:</span> <i className="fa fa-circle" > </i>بررسی</div>
            : Number(cars_item_list.stus) === 2 ?  
            <div className="in_val stus_2"><span className="xs-label">وضعیت:</span> <i className="fa fa-circle" > </i>عدم نمایش</div>
            : Number(cars_item_list.stus) === 3 ?  
            <div className="in_val stus_3"><span className="xs-label">وضعیت:</span> <i className="fa fa-circle" > </i>تایید شده</div>
            : Number(cars_item_list.stus) === 6 && 
            <div className="in_val stus_6"><span className="xs-label">وضعیت:</span> <i className="fa fa-circle" > </i>حذف شده</div>
            
        }

    
            
        </div>


        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 operate_sec ">
            <div className="in_val">
            <span className="xs-label">عملیات:</span> 

            {  
            //Number(cars_item_list.stus) !== 6 &&
            1==2 &&

              <button className="btns trash ajax_btn" onClick={this.do_confirm} title="حذف" data-val="6"  data-cars_id={cars_item_list.id}><i className="far fa-trash-alt"></i></button>
            }

            { Number(cars_item_list.stus) !== 6 ?

            <button className="btns edit" onClick={this.edit_modal} title="ویرایش" data-cars_id={cars_item_list.id}><i className="far fa-edit"></i></button>

            
            : null
            } 
              

              {  Number(cars_item_list.stus) === 2 ?
            <button className="btns hiden ajax_btn" onClick={this.do_update} title="نمایش" data-val="3" data-cars_id={cars_item_list.id}><i className="far fa-eye"></i></button>
            : Number(cars_item_list.stus) === 3 &&  
            <button className="btns hiden ajax_btn" onClick={this.do_update} title="عدم نمایش" data-val="2" data-cars_id={cars_item_list.id}><i className="far fa-eye-slash"></i></button>

        }

            {  (is_admin===true) &&
            <>
            <button className="btns stop ajax_btn" onClick={this.do_update} title="بررسی" data-val="1" data-cars_id={cars_item_list.id}><i className="far fa-times-circle"></i></button>
            <button className="btns done ajax_btn" onClick={this.do_update} title="تایید" data-val="3" data-cars_id={cars_item_list.id}><i className="far fa-check-circle"></i></button>
            </>
            
            }



            </div>
        </div>
   
</div>
</div>
]


   
   
)}






<div id="car_modal" className="modal fade users_modal edit_modal" role="dialog">
  <div className="modal-dialog">


    <div className="modal-content">
      <div className="modal-header">
        <button type="button" onClick={this.close_modal}  className="close pull-left" data-dismiss="modal">&times;</button>
        <h4 className="modal-title">ویرایش خودرو </h4>
        <span id="submit_date" className="disabled pull-left submit_date"></span>
      </div>
      <div className="modal-body row">
        <input type="hidden" id="car_id_val" />
        <input type="hidden" id="user_id_val" />

      <div className="col-sm-6">
        <div className="form-group">
        <label htmlFor="name_val"><i className="fa fa-car"></i> نام:</label>
        <input type="text" className="form-control" id="name_val" autoComplete="off" disabled />
        </div>
      </div>


      <div className="col-sm-3">
        <div className="form-group">
        <label htmlFor="stus_val"><i className="fa fa-sort-numeric-down"></i> مدل:</label>
          <input type="hidden"  id="model_val" />
          <select className="form-control model_val en_model_sel hidden">
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
            <option value="2018">2018</option>
            <option value="2017">2017</option>
            <option value="2016">2016</option>
            <option value="2015">2015</option>
            <option value="2014">2014</option>
            <option value="2013">2013</option>
            <option value="2012">2012</option>
            <option value="2011">2011</option>
            <option value="2010">2010</option>
            <option value="2009">2009</option>
            <option value="2008">2008</option>
            <option value="2007">2007</option>
            <option value="2006">2006</option>
            <option value="2005">2005</option>
            <option value="2004">2004</option>
            <option value="2003">2003</option>
            <option value="2002">2002</option>
            <option value="2001">2001</option>
            <option value="2000">2000</option>
            <option value="1999">1999</option>
            <option value="1998">1998</option>
            <option value="1997">1997</option>
            <option value="1996">1996</option>
            <option value="1995">1995</option>
          </select>

          <select className="form-control model_val fa_model_sel hidden">
            <option value="1400">1400</option>
            <option value="1399">1399</option>
            <option value="1398">1398</option>
            <option value="1397">1397</option>
            <option value="1396">1396</option>
            <option value="1395">1395</option>
            <option value="1394">1394</option>
            <option value="1393">1393</option>
            <option value="1392">1392</option>
            <option value="1391">1391</option>
            <option value="1390">1390</option>
            <option value="1389">1389</option>
            <option value="1388">1388</option>
            <option value="1387">1387</option>
            <option value="1386">1386</option>
            <option value="1385">1385</option>
            <option value="1384">1384</option>
            <option value="1383">1383</option>
            <option value="1382">1382</option>
            <option value="1381">1381</option>
            <option value="1380">1380</option>
            <option value="1379">1379</option>
            <option value="1378">1378</option>
            <option value="1377">1377</option>
            <option value="1376">1376</option>
            <option value="1375">1375</option>
            <option value="1374">1374</option>
          </select>

        </div>
      </div>














      <div className="col-sm-3">
        <div className="form-group">
        <label htmlFor="stus_val"><i className="fa fa-palette"></i> رنگ:</label>
          <select className="form-control" id="color_val">

                            <option value="سفید">سفید</option>
                            <option value="مشکی">مشکی</option>
                            <option value="کرم">کرم </option>
                            <option value="زرد">زرد </option>
                            <option value="خاکستری">خاکستری </option>
                            <option value="نقره ای">نقره ای </option>
                            <option value="آبی">آبی </option>
                            <option value="قرمز">قرمز </option>
                            <option value="سبر">سبر</option>
                            <option value="قهوه ای">قهوه ای </option>
                            <option value="سرمه ای">سرمه ای </option>
                            <option value="زرشکی">زرشکی </option>
                            <option value="صورتی">صورتی </option>
                            <option value="بنفش">بنفش </option>
                            <option value="نارنجی">نارنجی </option>
                            <option value="شیری">شیری </option>
                            <option value="بژ">بژ </option>
                            <option value="لجنی">لجنی </option>
                            <option value="فیروزه ای">فیروزه ای </option>
          </select>

        </div>
      </div>


      <div className="col-sm-3">
        <div className="form-group">
        <label htmlFor="city_val"><i className="fa fa-map-marker-alt"></i> شهر:</label>
          <select className="form-control" id="city_val">
          {stok_city_arr.map((item,index) => (
            <option key={index} value={item[0]+","+item[1]}>{item[0]}</option>
          ))}
          </select>

        </div>
      </div>


      <div className="col-sm-6">
        <div className="form-group">
        <label htmlFor="price_val"><i className="fa fa-tags"></i> قیمت اجاره یک شب  
                <small> (تومان): </small>   </label>
        <input type="text" className="form-control ltr_dir let_space turn_number" id="price_val" autoComplete="off"/>
        <div className="leter_digit">صفر تومان</div>
        </div>
      </div>



      <div className="col-sm-3 img_sec_2">
      
      <img src="/img/user_img.svg"  alt="Image"  />


      </div>


      <div className="clearfix"></div>
      <hr/>

      <div className="col-sm-12">
      <div className="form-group">
        <label htmlFor="price_val"><i className="fa fa-list-alt"></i> توضیحات: </label>
        <textarea className="classic_textarea" placeholder="توضیحات یا ویژگی های خودرو... (اختیاری)"  id="extra_val" cols="30" rows="4"></textarea>
        </div>
      </div>

      

      </div>
      <div className="modal-footer">
      <button className="btn submit remove_btn  hidden" id="remove-form-btn" onClick={this.delete_form}  > <span> حذف </span> </button>


         <button className="btn submit save_form_btn hidden" id="update-form-btn" onClick={this.update_form}  > <span> بروزرسانی</span> <i className="fa fa-cog fa-spin ico-2"></i> </button>
        
        <button type="button"  onClick={this.close_modal} className="close_modal" id="close_modal" >انصراف</button>
      </div>
    </div>

  </div>
</div>






</div>




</div>




</div>
</div>


    )
  }
}





