import React from 'react';
import ReactDOM from 'react-dom';
import Sidebar from './layout/Sidebar';
import Main from './layout/Main';
import Login from './layout/Login';
import axios from 'axios';



import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';



  const requireAuth = () => {

  if( localStorage.getItem('_token') ) {

    async function asyncFunc() {

      axios.get('https://rentjoo.com/panel/dev/api/profile.php',{
        params:{
          action_type:"login_info",
          user_number:localStorage.getItem('user_Num'),
          user_password:localStorage.getItem('user_Pass'),
  
  
        }
      })
        .then(res => {
          const login_res = res.data;
          if(login_res !== null){

            if(localStorage.getItem('user_Pass') !== login_res["pass"]){
              localStorage.clear();
              window.location.reload(); 
            }

          }

          else{
            localStorage.clear();
            window.location.reload(); 
          }
          //this.setState({ login_res });
        })
  
  
    }
   // asyncFunc();

  
return   <React.StrictMode> <BrowserRouter> <Sidebar /> <Main /> </BrowserRouter> </React.StrictMode>

  }
  else  {

    localStorage.clear();


    return   <Login /> 



   }
  // stay on this route since the user is authenticated

}

requireAuth();

ReactDOM.render(


[

  requireAuth()

],

  document.getElementById('wrapper')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
